.or-class::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 55%;
  border-bottom: 1px solid rgb(228, 228, 228);
  width: 200px;
  z-index: 2;
}

.or-class::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 55%;
  border-bottom: 1px solid rgb(228, 228, 228);
  width: 200px;
  z-index: 2;
}
