.underline-class {
  position: relative;
}

.underline-class:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #4f46e5;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.underline-class:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
