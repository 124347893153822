.side-menu-enter {
  transform: translateX(-150%);
}

.side-menu-enter-active {
  transform: translateX(0%);
  transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 400ms;
}

.side-menu-exit {
  transform: translateX(0px);
}

.side-menu-exit-active {
  transform: translateX(-150%);
  transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 400ms;
}
